<template>
  <div class="notice">
    <NavBar name="巡检日志"></NavBar>
    <div style="margin-top: 20px">
      <el-form :inline="true">
        <el-form-item>
          <myInput @clear="getList" @keydown="$keyDownSearch($event, getList)" v-model="formInline.worker_name"
            placeholder="请输入巡检人姓名">
            <template #pre>巡检人姓名:</template>
          </myInput>
          <!-- <el-input placeholder="巡检人姓名" clearable v-model="formInline.worker_name"></el-input> -->
        </el-form-item>
        <el-form-item>
          <myInput @clear="getList" @keydown="$keyDownSearch($event, getList)" v-model="formInline.inspection_task_name"
            placeholder="请输入计划名称">
            <template #pre>计划名称:</template>
          </myInput>
          <!-- <el-input placeholder="计划名称" clearable v-model="formInline.inspection_task_name"></el-input> -->
        </el-form-item>
        <el-form-item>
          <el-date-picker type="daterange" v-model="date"
            @change="$timeChange($event, formInline, 'beginTime', 'endTime')" value-format="yyyy-MM-dd"
            placeholder=""></el-date-picker>
        </el-form-item>
        <el-form-item>
          <MyButton title="搜索" @click="getList">
            <template slot="preImage">
              <img src="@/assets/img/icon/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton :accessId="8472231" title="导出" @click="printEvent()">
            <template slot="preImage">
              <img src="@/unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }" :cell-style="{ 'text-align': 'center' }" :data="tableData">
        <!-- <el-table-column label="日期" prop="create_at"></el-table-column> -->
        <el-table-column label="计划名称" prop="inspection_task_name"></el-table-column>
        <!-- <el-table-column label="巡检人" prop="worker_name"></el-table-column> -->
        <el-table-column label="巡检线路" prop="task_way">
          <template #default="{ row }">
            <div v-if="row.inspection_type == 10">
              <span v-for="(item, index) in row.way_list" :key="item.way_id">
                <span :style="{ color: item.colour == 1 ? 'green' : item.colour == 2 ? '#E90000' : 'black' }">{{
                  item.inspection_sort }}</span>
                <span v-if="index < row.way_list.length - 1 && item.colour == 1" style="color: green">→</span>
                <span v-if="index < row.way_list.length - 1 && item.colour == 2" style="color: #e90000">→</span>
                <span v-if="index < row.way_list.length - 1 && item.colour == 0" style="color: black">→</span>
              </span>
            </div>
            <div v-else>
              <span v-for="(item, index) in row.way_list" :key="item.way_id">
                <span v-if="item.colour == 1" style="color: green">{{
                  item.inspection_sort
                }}</span>
                <span v-else-if="item.colour == 2" style="color: #e90000">{{
                  item.inspection_sort
                }}</span>
                <span v-else style="color: black">{{
                  item.inspection_sort
                }}</span>
                <span v-if="index < row.way_list.length - 1">,</span>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="巡检开始时间" prop="task_time"></el-table-column>
        <el-table-column label="巡检结束时间" prop="task_end_time"></el-table-column>
        <el-table-column label="总时长（分钟）" prop="poor_minute">
          <template #default="{ row }">
            {{ getTimeMinute(row.task_time, row.task_end_time) }}
          </template>
        </el-table-column>
        <el-table-column label="异常问题" prop="create_at">
          <template #default="{ row }">
            <span v-if="row.abnormal_log_number <= 0">{{ row.abnormal_count }}</span>
            <span style="cursor: pointer; color: #e90000" v-else @click="abnormarshow(row)">
              {{ row.abnormal_count }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="create_at">
          <template #default="{ row }">
            <pop :accessId="847224" tips="签到日志" @myclick="signLog(row)" popRight>
              <img class="icon" src="../../../assets/img/icon/chakan.png" alt />
            </pop>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="apiPage">
      <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total"
        :page-size="formInline.pageSize" :current-page="formInline.pageNum" @current-change="getList"></el-pagination>
    </div>

    <!-- 异常问题 -->
    <el-dialog title="异常问题" :visible.sync="abnormalvis" :close-on-click-modal="false">
      <el-table :data="abnormalData">
        <el-table-column type="index" title="序号"></el-table-column>
        <el-table-column prop="abnormal_title" label="问题说明"></el-table-column>
        <el-table-column width="180" prop="create_at" label="发现时间"></el-table-column>
        <el-table-column label="处理情况">
          <template #default="{ row }">
            <span v-if="row.abnormal_status == 20">已处理</span>
            <span v-else style="color: #e90000">未处理</span>
          </template>
        </el-table-column>
        <el-table-column prop="inspection_time" label="处理时间"></el-table-column>
        <el-table-column prop="worker_name" label="处理人"></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="签到日志" :visible.sync="signLogModel" :close-on-click-modal="false" width="500px">
      <el-table :data="signLogList">
        <el-table-column type="index" title="序号"></el-table-column>
        <el-table-column prop="worker_name" label="签到人"></el-table-column>
        <el-table-column prop="inspection_sort" label="点位"></el-table-column>
        <el-table-column width="180" prop="create_at" label="签到时间"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "notice",
  data () {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
        status: null,
        worker_name: "",
        inspection_task_name: "",
        beginTime: "",
        endTime: "",
      },
      date: "",
      total: 0,
      tableData: [],
      abnormalvis: false,
      abnormalData: null,
      signLogModel: false,
      signLogList: []
    };
  },
  created () {
    this.getList();
  },
  methods: {
    signLog (row) {
      this.$request.HttpGet('/inspection_task_sign/signLog', {
        supervise_id: row.supervise_id
      }).then((res) => {
        this.signLogList = res.data.list || [];
        this.signLogModel = true;
      })
    },
    getTimeMinute (startTime, endTime) {
      const time = new Date(endTime) - new Date(startTime);
      return Math.floor(time / (60 * 1000));
    },
    getList (e = 1) {
      this.formInline.pageNum = e;
      console.log(this.formInline);
      this.tableData = [];
      this.$request.HttpGet("/inspection_task_sign/list", this.formInline).then((res) => {
        if (res.data) {
          this.tableData = res.data.list;
          this.total = res.data.total;
          console.log(this.tableData);
        }
      });
    },
    printEvent () {
      // this.$refs.xTable.exportData({
      // 		type: 'csv',
      // 		filename: `巡检日志列表${new Date().toLocaleString()}`
      // 	})
      this.$exported(
        "/inspection_task_sign/export",
        this.formInline,
        "巡检日志.xlsx"
      );
    },

    abnormarshow (item) {
      this.$request.HttpPost("/inspection_task_log/list", {
        sign_id: item.sign_id,
        // inspection_task_id: item.inspection_task_id,
        supervise_id: item.supervise_id,
        abnormal_status: 10,
      }).then((res) => {
        if (res.data && res.data.list) {
          this.abnormalData = res.data.list;
        } else {
          this.abnormalData = null;
        }
      });
      this.abnormalvis = true;
    },
    printEvented () {
      this.$refs.xTable.print();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.search {
  .el-input__inner {
    margin-top: -2px;
  }
}

.ysall {
  display: inline-block;
  width: 160px;
  height: 32px;
  background: #02ce80;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  padding: 0 10px;
  box-sizing: border-box;
  line-height: 32px;

  span {
    &:nth-child(2) {
      font-weight: bold;
      margin-left: 12px;
    }
  }
}
</style>
